<template>
    <div class="video-slide">
        <div class="slide video">
        <parallax :hasOverlay="false" :scrollRatio="2">
            <template #video>
                <video autoplay muted loop playsinline>
                    <source :src="url" :type="mediaType"/>
                </video>
            </template>
        </parallax>
        </div>
        <div class="slide content">
            <slot name="text-content"></slot>
        </div>
    </div>
</template>
<script>
import Parallax from '../Parallax.vue'
export default {
    components: { Parallax },
    name: "VideoSlide",
    tag: "video-slide",
    props: {
        "url": { default: "" },
        "mediaType": { default: "video/mp4"},
    },
    data() {
        return {
        }
    }
}
</script>