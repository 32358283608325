<template>
    <div class="parallax" id="pax" ref="pax" >
        <div v-if="hasOverlay" class="overlay" :class="overlayClass"></div>
        <div class="media-content" id="mc" ref="mc" :class="contentClass" :style="scrollValue">
            <slot name="video"></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: "Parallax",
    tag: "parallax",
    props: {
        "hasOverlay": { default: "true" },
        "overlayClass": { default: ""},
        "contentClass": { default: ""},
        "scrollRatio": { default: 2 }
    },
    data() {
        return {
            scrollElement: null,
            parallaxX: 0,
            parallaxY: 0
        }
    },
    mounted() {
        this.scrollElement = document.querySelector(".scroller");
        if (this.scrollElement) {
            this.scrollElement.addEventListener("scroll", this.setScrollValues);
            window.addEventListener("resize", this.setScrollValues);
        }
        this.setScrollValues();
    },
    unmounted() {
        if (this.scrollElement) {
            this.scrollElement.removeEventListener("scroll", this.setScrollValues);
            window.removeEventListener("resize", this.setScrollValues);
        }
    },
    computed: {
        scrollValue() { 
            return "transform: translate3d(" + this.parallaxX + "px," + Math.max(this.parallaxY / this.scrollRatio) + "px, 0px);"}
    },
    methods: {
        setScrollValues() {
            this.parallaxY = this.scrollElement?.scrollTop || 0;
            this.parallaxX = (this.$refs.pax.offsetWidth - this.$refs.mc.offsetWidth) * .5;
        }
    }
}
</script>