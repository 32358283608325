<template>
    <div class="landing-band">
        <slot name="header"></slot>
        <slot name="body"></slot>
        <slot name="footer">
            
            <p class="trial-legend">{{$t("landing.buttonLegend")}}</p>
        </slot>
    </div>
</template>
<script>
export default {
    name: "LandingBand",
    tag: "landing-band",
    data() {
        return {
        }
    }
}
</script>
