<template>
  <div>
    <slot name="top"></slot>
    <div class="footer-row">
      <div class="links">
        <div v-for="item in items" :key="item" :class="item.extra">
          <a :target="item.target" :href="item.value">{{item.description}}</a>
          <span>|</span>
        </div>
      </div>
      <span>&copy; 2022-2023 Cybele Software, Inc.</span><span class="hiddenmini">&nbsp;All Rights Reserved.</span>
    </div>
    <slot name="bottom"></slot>
  </div>
</template>
<script>
import { apiConfig } from '../../config/backend-api';

const footerLinks = [
  { "key": "howItWorks", "extra": "hiddenmini" },
  { "key": "help", "extra": "hiddenmini" },
  { "key": "contact", "extra": "hiddenmini" },
  { "key": "support" , "extra": ""},
  { "key": "privacyPolicy", "extra": "" }
]

export default {
  name: "LandingFooter",
  tag: "landing-footer",
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    items: function() {
      let items = {};
      for (let i=0; i < footerLinks.length; i++) {
        let value = apiConfig.getConfigUrlValue(footerLinks[i].key);
        if (value) {
          items[footerLinks[i].key] = { "description": this.$t('login.footer.'+footerLinks[i].key), "value": value, "extra": footerLinks[i].extra, "target":  footerLinks[i].key};
        }
      }
      return items;
    }
  }
};
</script>