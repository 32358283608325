<template>
  <div class="header-back">
    <div class="landing-header p-mt-0 p-grid">
      <div class="p-col-6 p-md-3">
        <slot name="left">
         <img @click="goto('hone')" class="logo p-cursor-pointer" src="/images/thinfinity.svg" />
        </slot>
      </div>
      <div class="p-col-0 p-md-7">
        <ul class="menu-list">
          <li v-for="i in links.length" :key="i" class="header-element element-link" @click="goto(links[i-1].id)">
            {{links[i-1].caption}}
          </li>
        </ul>
      </div>
      <div class="p-col-6 p-md-2 cta">
        <router-link to="/login" class="p-button-login">
        {{$t("landing.action")}}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LandingHeader",
  tag: "landing-header",
  props: ["links", "scroller"],
  data() {
    return {
      scroll_element: window
    };
  },
  mounted() {
    if (this.scroller) this.scroll_element = document.getElementById(this.scroller)
  },
  methods: {
    goto(id) {
      let element = document.getElementById(id);
      if (element) {
        let headerOffset = 100;
        let elementPosition = element.getBoundingClientRect().top;
        let offsetPosition = elementPosition + window.pageYOffset + this.scroll_element.scrollTop - headerOffset;
        this.scroll_element.scrollTo({
          top: offsetPosition,
            behavior: "smooth"
        });
      }
      //document.getElementById(id).scrollIntoView();
    }
  }
};
</script>