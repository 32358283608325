<template>
    <div v-if="show" class="cookies-consent-container">
      <div class="inner p-d-flex">
        <div class="p-d-flex p-ai-center">
          <span>{{$t("cookiesConsent.legend")}}</span>
        </div>
        <div class="buttons-container p-d-flex p-ai-center p-jc-end">
          <Button type="button" class="p-button-login cookie-button" :label="$t('cookiesConsent.accept')" @click="acceptRules"></Button>
          <Button type="button" class="p-button-link read-more" :label="$t('cookiesConsent.readMore')" @click="openReadMore"></Button>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    name: "cookies-consent",
    tag: "cookies-consent",
    props: {
      "policyURL": { default: "" }
    },
    data() {
        return {
          show: true,
        }
    },
    mounted() {
      this.checkConsent();
    },
    methods: {
      acceptRules() {
        localStorage.setItem('consent', true);
        this.checkConsent();
      },
      checkConsent() {
        this.show = !localStorage.getItem('consent');
      },
      openReadMore() {
        window.open(this.policyURL, 'readMore');
      }
    }
}
</script>