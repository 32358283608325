<template>
  <div class="landing-home scroller" id="scroller">
    <landing-header :links="sections" :scroller="'scroller'"></landing-header>
    <div class="landing-content" id="home"><a id="hone"></a>
      <video-slide :url="videoUrl" class="video-slide">
        <template #text-content>
          <div class="slide-content">
            <p class="header-legend">{{$t("landing.video.legend")}}</p>
            <h1 class="h1">{{$t("landing.video.title")}}</h1>
             <router-link to="/register?utm_source=Visitor&utm_medium=Landing_VideoStrip&utm_campaign=TRWO&utm_content=Cta_FreeTrial" target="register" class="p-button-login">
                {{$t("landing.buttonTrial")}}
            </router-link>
            <p class="trial-legend">{{$t("landing.buttonLegend")}}</p>
          </div>
        </template>
      </video-slide>
      <div class="gray-container">
        <landing-band class="p-lg-offset-2 p-lg-8 p-md-offset-0 p-md-12" id="widoes">
          <template #header><a id="what"></a>
            <h2 class="h2 p-m-md-5 p-m-sm-1">{{$t("landing.strip2.title")}}</h2>
          </template>
          <template #body>
            <div class="p-grid">
              <div class="p-col-12 p-md-4 box">
                <div class="img-header pb-3">
                  <img src="/images/landing/Webapps-and-portals.svg" :alt="$t('landing.strip2.body.box1.title')"/>
                </div>
                <h3>{{$t("landing.strip2.body.box1.title")}}</h3>
                <ul class="box-list">
                  <li>{{$t("landing.strip2.body.box1.list.item1")}}</li>
                  <li>{{$t("landing.strip2.body.box1.list.item2")}}</li>
                  <li>{{$t("landing.strip2.body.box1.list.item3")}}</li>
                </ul>
              </div>
              <div class="p-col-12 p-md-4 box">
                <div class="img-header pb-3">
                  <img src="/images/landing/Windows-and-Desktops-apps.svg" :alt="$t('landing.strip2.body.box2.title')"/>
                </div>
                <h3>{{$t("landing.strip2.body.box2.title")}}</h3>
                <ul class="box-list">
                  <li>{{$t("landing.strip2.body.box2.list.item1")}}</li>
                  <li>{{$t("landing.strip2.body.box2.list.item2")}}</li>
                  <li>{{$t("landing.strip2.body.box2.list.item3")}}</li>
                </ul>
              </div>
              <div class="p-col-12 p-md-4 box">
                <div class="img-header pb-3">
                  <img src="/images/landing/Web-folders-and-files.svg" :alt="$t('landing.strip2.body.box3.title')"/>
                </div>
                <h3>{{$t("landing.strip2.body.box3.title")}}</h3>
                <ul class="box-list">
                  <li>{{$t("landing.strip2.body.box3.list.item1")}}</li>
                  <li>{{$t("landing.strip2.body.box3.list.item2")}}</li>
                  <li>{{$t("landing.strip2.body.box3.list.item3")}}</li>
                </ul>
              </div>
              <router-link to="/register?utm_source=Visitor&utm_medium=Landing_Strip_1&utm_campaign=TRWO&utm_content=Cta_FreeTrial" target="register" class="p-button-login trial">
                {{$t("landing.buttonTrial")}}
            </router-link>
            </div>
          </template>
        </landing-band>
      </div>
      <div class="light-container">
        <landing-band class="p-lg-offset-2 p-lg-8 p-md-offset-0 p-md-12" id="getstarted">
          <template #header><a id="how"></a>
            <h2 class="h2 p-m-md-5 p-m-sm-1">{{$t("landing.strip1.title")}}</h2>
          </template>
          <template #body>
            <div class="p-grid">
              <div class="p-col-12 p-md-4 box ">
                <div class="img-header">
                  <img src="/images/landing/Sign-up.svg" :alt="$t('landing.strip1.body.box1.title')" />
                </div>
                <h3 class="step">{{$t("landing.strip1.body.box1.title")}}</h3>
                <p class="box-text">{{$t("landing.strip1.body.box1.legend")}}</p>
              </div>
              <div class="p-col-12 p-md-4 box">
                <div class="img-header">
                  <img src="/images/landing/Create-a-workspace.svg" :alt="$t('landing.strip1.body.box2.title')"/>
                </div>
                <h3 class="step">{{$t("landing.strip1.body.box2.title")}}</h3>
                <p class="box-text">{{$t("landing.strip1.body.box2.legend")}}</p>
              </div>
              <div class="p-col-12 p-md-4 box">
                <div class="img-header">
                  <img src="/images/landing/Use-your-workspaces.svg" :alt="$t('landing.strip1.body.box3.title')"/>
                </div>
                <h3 class="step">{{$t("landing.strip1.body.box3.title")}}</h3>
                <p class="box-text">{{$t("landing.strip1.body.box3.legend")}}</p>
              </div>
              <router-link to="/register?utm_source=Visitor&utm_medium=Landing_Strip_2&utm_campaign=TRWO&utm_content=Cta_FreeTrial" target="register" class="p-button-login trial">
                {{$t("landing.buttonTrial")}}
            </router-link>
            </div>
          </template>
        </landing-band>
      </div>
      <!-- <div class="gray-container">
        <landing-band class="p-lg-offset-2 p-lg-8 p-md-offset-0 p-md-12" id="pricing">
          <template #header><a id="howmuch"></a>
            <h2 class="text-center p-m-md-5 p-m-sm-1 h2">{{$t("landing.pricing.title")}}</h2>
          </template>
          <template #body>
            <div class="p-mt-1">
              <p v-for="row in pricing_legends.length" :key="row" class="text-center legend-pricing">{{pricing_legends[row - 1]}}</p>
              <div class="p-mt-0 pricing">
                <div class="p-col-12 p-pt-5 p-text-center">
                  <double-toggle v-model="monthly"/>
                </div>
                <div class="p-col-12">
                  <div class="p-plans">
                    <plan-card-features :show-features="onlyChecks" v-model="monthly"></plan-card-features>
                    <div :class="{ 'plan-container': joinedCards }" @mouseleave="onLeave">
                      <plan-card v-for="plan in 3" :key="plan" :plan="plan" :quiet="isQuiet" :monthly="monthly" :only-checks="onlyChecks" :indy="!joinedCards" selectable="false" :id="'plan'+plan" @mouseenter="onEnter" :action-buttons="false" @select="select"></plan-card>
                    </div>
                  </div>
                </div>
              </div>
              <router-link to="/register?utm_source=Visitor&utm_medium=Landing_Strip_3&utm_campaign=TRWO&utm_content=Cta_FreeTrial" target="register" class="p-button-login trial">
                {{$t("landing.buttonTrial")}}
            </router-link>
            </div>
          </template>
        </landing-band>
      </div> -->
      <landing-band class="dark-container">
        <template #header><a id="contact"></a>
          <h2 class="h2"><span class="nowrap">{{$t("landing.strip5.title")}}</span></h2>
        </template>
        <template #body>
          <p class="p-my-5 big-t">{{$t("landing.strip5.legend")}}</p>
        </template>
        <template #footer>
          <a :href="contactURL + '?utm_source=Visitor&utm_medium=Landing_Strip_4&utm_campaign=TRWO&utm_content=Cta_ContactUs'" target="contact" class="p-button-login footer-buttons">{{$t("landing.strip5.cta1")}} <span class="thin-email"></span> </a>
          <a :href="meetingsURL  + '?utm_source=Visitor&utm_medium=Landing_Strip_4&utm_campaign=TRWO&utm_content=Cta_BookCall'" target="call" class="p-button-login footer-buttons">{{$t("landing.strip5.cta2")}} <span class="thin-calling"></span> </a>
        </template>
      </landing-band>
      <landing-footer class="landing-footer dark">
        <template #bottom>
          <div class="address">3422 Old Capitol Trail - Suite 1125, Wilmington DE 19808, United States</div>
          <div class="center"><a href="https://cybelesoft.com" target="cybele"><img class="logo" src="/images/cybele-white.svg"></a></div>
        </template>
      <landing-footer/>
      </landing-footer>
    </div>
    <cookies-consent :policyURL="privPolicyURL"></cookies-consent>
  </div>
  <resizer @resize="onResize"></resizer>
</template>

<script>
import LandingBand from "../components/landing/LandingBand.vue";
import VideoSlide from "../components/landing/VideoSlide.vue";
import LandingHeader from "../components/landing/LandingHeader.vue"
import LandingFooter from "../components/landing/LandingFooter.vue"
// import PlanCardFeatures from "../components/plans/PlanCardFeatures.vue";
// import DoubleToggle from "../components/DoubleToggle.vue";
// import PlanCard from "../components/plans/PlanCard.vue";
import Resizer from "../components/Resizer.vue";
import CookiesConsent from '../components/landing/CookiesConsent.vue';
import { apiConfig } from '../config/backend-api';

const MS_TIMEOUT = 1000, MIN_TABLE_WIDTH = 1200, MIN_JOINED_TABLE = 960, MIN_WIN_HEIGHT = 820;
export default {
  data() {
    return {
      monthly: false,
      onlyChecks: false,
      joinedCards: true,
      isQuiet: false,
      videoUrl: "/images/landing/landing-background-video.mp4",
      privPolicyURL: apiConfig.getConfigUrlValue('privacyPolicy'),
      remnoteWorkspaceURL: apiConfig.getConfigUrlValue('remoteWorkspace'),
      contactURL: apiConfig.getConfigUrlValue('contact'),
      meetingsURL: apiConfig.getConfigUrlValue('meetings'),
      sections: [

        { id:"what", caption: this.$t("landing.menu_options.what") },
        { id:"how", caption: this.$t("landing.menu_options.how") },
        // { id:"howmuch", caption: this.$t("landing.menu_options.howmuch") },
        { id:"contact", caption: this.$t("landing.menu_options.contact") }
      ]
    }
  },
  computed: {
      pricing_legends: function() { return this.$t("landing.pricing.legends").split("#"); }
  },
  components:{
    VideoSlide,
    LandingBand,
    LandingHeader,
    LandingFooter,
    // PlanCardFeatures,
    // DoubleToggle,
    // PlanCard,
    Resizer,
    CookiesConsent
  },
  methods: {
    onEnter() {
      if (this.timeout) clearTimeout(this.timeout);
      this.isQuiet = false;
    },
    onLeave() {
      this.timeout = setTimeout(function(_ref) { _ref.isQuiet = true; _ref.timeout = null  }, MS_TIMEOUT, this);
    },
    select(p) {
      if (!this.joinedCards)
        document.getElementById("plan"+p).scrollIntoView({ behavior: "smooth" });
    },
    onResize({ width, height }) {
      let oc = width >= MIN_TABLE_WIDTH && height >= MIN_WIN_HEIGHT; if (oc != this.onlyChecks) { this.onlyChecks = oc; }
      let jc = width >= MIN_JOINED_TABLE; if (jc != this.joinedCards) { this.joinedCards = jc; }
    }
  },
};
</script>